<template>
    <div class="flexible-section">
        <template v-for="(section, key) in sections">
            <component
                :is="getComponent(section.__typename)"
                v-if="section.__typename"
                :key="key"
                :content="section"
            />
        </template>
    </div>
</template>

<script>
export default {
    props: {
        sections: {
            type: Array,
            required: false,
            default: () => { return []; }
        }
    },

    data() {
        return {
            map: {
                flexibleSection_richText_BlockType: 'FlexibleSectionsBlocksRichText', // eslint-disable-line camelcase
                flexibleSection_image_BlockType: 'FlexibleSectionsBlocksImage', // eslint-disable-line camelcase
                flexibleSection_fullWidthImage_BlockType: 'FlexibleSectionsBlocksFullWidthImage', // eslint-disable-line camelcase
                flexibleSection_textImage_BlockType: 'FlexibleSectionsBlocksTextImage', // eslint-disable-line camelcase
                flexibleSection_button_BlockType: 'FlexibleSectionsBlocksButton', // eslint-disable-line camelcase
                flexibleSection_accordion_BlockType: 'FlexibleSectionsBlocksAccordion', // eslint-disable-line camelcase
                flexibleSection_linkBlocks_BlockType: 'FlexibleSectionsBlocksLinkBlocks', // eslint-disable-line camelcase
                flexibleSection_quote_BlockType: 'FlexibleSectionsBlocksQuote', // eslint-disable-line camelcase
                flexibleSection_video_BlockType: 'FlexibleSectionsBlocksVideo', // eslint-disable-line camelcase
                flexibleSection_blogSelect_BlockType: 'FlexibleSectionsBlocksBlogSelect', // eslint-disable-line camelcase
                flexibleSection_photoGallery_BlockType: 'FlexibleSectionsBlocksPhotoGallery', // eslint-disable-line camelcase
                flexibleSection_linkBlockHighlight_BlockType: 'FlexibleSectionsBlocksLinkBlockHighlight', // eslint-disable-line camelcase
                flexibleSection_downloadForm_BlockType: 'FlexibleSectionsBlocksDownloadForm', // eslint-disable-line camelcase
                flexibleSection_profileCardsLarge_BlockType: 'FlexibleSectionsBlocksProfileCardsLarge', // eslint-disable-line camelcase
                flexibleSection_profileCardsSmall_BlockType: 'FlexibleSectionsBlocksProfileCardsSmall', // eslint-disable-line camelcase
                flexibleSection_tipSlider_BlockType: 'FlexibleSectionsBlocksTipSlider', // eslint-disable-line camelcase
                flexibleSection_iconList_BlockType: 'FlexibleSectionsBlocksIconList', // eslint-disable-line camelcase
                flexibleSection_curriculum_BlockType: 'FlexibleSectionsBlocksCurriculum', // eslint-disable-line camelcase
                flexibleSection_partnerSlider_BlockType: 'FlexibleSectionsBlocksPartnerSlider', // eslint-disable-line camelcase
                flexibleSection_tiktok_BlockType: 'FlexibleSectionsBlocksTikTok', // eslint-disable-line camelcase
                flexibleSection_socials_BlockType: 'FlexibleSectionsBlocksSocials', // eslint-disable-line camelcase
                flexibleSection_formie_BlockType: 'FlexibleSectionsBlocksFormie', // eslint-disable-line camelcase
                flexibleSection_usps_BlockType: 'FlexibleSectionsBlocksUSPs', // eslint-disable-line camelcase
                flexibleSection_eventBrite_BlockType: 'FlexibleSectionsBlocksEventBrite', // eslint-disable-line camelcase
                flexibleSection_campusBlocks_BlockType: 'FlexibleSectionsBlocksCampusBlocks', // eslint-disable-line camelcase
                flexibleSection_announcementCard_BlockType: 'FlexibleSectionsBlocksAnnouncementCard', // eslint-disable-line camelcase
                flexibleSection_announcementCards_BlockType: 'FlexibleSectionsBlocksAnnouncementCards', // eslint-disable-line camelcase
                flexibleSection_photoCollage_BlockType: 'FlexibleSectionsBlocksPhotoCollage', // eslint-disable-line camelcase
                flexibleSection_facilityList_BlockType: 'FlexibleSectionsBlocksFacilityList', // eslint-disable-line camelcase
                flexibleSection_anchor_BlockType: 'FlexibleSectionsBlocksAnchor', // eslint-disable-line camelcase
                flexibleSection_factsAndFigures_BlockType: 'FlexibleSectionsBlocksFactsAndFigures', // eslint-disable-line camelcase
            }
        };
    },

    methods: {
        /**
         * Retrieve the block's matching component name
         *
         * @param   {Object}  block  Content block object, as provided by the API.
         * @return  {String}         Block's Vue component name
         */
        getComponentName(block) {
            return this.map?.[block] ?? null;
        },

        /**
         * Retrieve the block's matching component
         *
         * @param {Object} block
         * @returns {ConcreteComponent | string}
         */
        getComponent(block) {
            return resolveComponent(this.getComponentName(block));
        },
    }
};
</script>
